import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const WebAndAppdesign = () => {
    return (
        <>
             <div className="services-bg">
      <Container>
        <Row>
        <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center header-content flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                {/* <div className=" text-left">
                  <span className="welcome">WEB DESIGN</span>
                </div> */}
                <div>
                  <h1 className="separator_off">
                  WEB DESIGN
                  </h1>
                  {/* <br />
                  <h6 className="subtitle">
                  Website and App development solution for transforming and innovating businesses.
                  and innovating businesses.
                  </h6> */}
                </div>
              </div>
            </Col>  
          {/* <Col>
          <div className="android-img services-inner-img">
                <img src="./img/WEB DESIGN.png" width={"90%"} />
              </div>
          </Col> */}
        </Row>
        </Container>
    </div>
            <Container>
                <Row>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-img services-inner-img">
                            <img src="./img/WEB DESIGN.png" alt="" />
                        </div>
                    </Col>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-text">
                            <h2>WEB & MOBILE APP DESIGN</h2>
                            <p>Web design is not just about creating pretty layouts. It’s about understanding the marketing challenge behind your business.</p><br />
                            <h2>Why is web design important?</h2>
                            <p>Web design is not just about aesthetics. The usability of the site is of paramount importance. Users need to be able to navigate and understand your site in a simple interface. <br /><br />Style is important too. As the web develops users become used more advanced sites, their level of expectations have risen. Your site needs to present the right image to customers. This is why web design is important. </p>
                            <h2>Why choose us?</h2>
                            <p>Our team of expert designers will deliver strong and successful website designs and mobile app and game design. Creativity is balanced with usability in order to develop a seamless experience. This kind of enhanced user experience drives and improves sales. <br /> <br />Our Design team work closely with our Developers, Account managers and strategic marketers to produce a beautifully crafted website. </p>
                        </div>
                        <div className="android-text">
                            <ul>Our Source</ul>
                            <li>Adobe Photoshop</li>
                            <li>Figma</li>
                            <li>Adobe Xd</li>
                            <li>Adobe Illustrator</li>
                            <li>HTML</li>
                            <li>Scss</li>
                            <li>Tailwindcss</li>
                            <li>Ant Design</li>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default WebAndAppdesign

import React, { useState } from "react";
import "./life7seasol.css";
import "./jq.js";
import { Col, Container, Row } from "react-bootstrap";
import Life7seasolcomponent from "./Life7seasolcomponent";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import ReactPlayer from "react-player";
// import VideoURL2 from "http://7seasol.com/vv/day2.mp4";
// import VideoURL1 from "http://7seasol.com/vv/day1.mp4";
import closevideoicon from "../../img/wrong.png";


const Life7seasol = (props) => {
  const history = useHistory();
  const [eventName, seteventName] = useState("birthday");
  const [playVideo, setPlayVideo] = useState(false);
  const [play, setPlay] = useState(true);
  const [videoURL, setVideoURL] = useState()

  const diwalievent = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  }


  const playVideos = (data) => {
    if (data === 'day-1') {
      setPlayVideo(true)
      setVideoURL('http://7seasol.com/vv/day1.mp4')
    }

    if (data === 'day-2') {
      setPlayVideo(true)
      setVideoURL( 'http://7seasol.com/vv/day2.mp4')
    } 
  }

  const diwali_event_images = [
    {
      img: "/img/diwali-1.jpg",
    },
    {
      img: "/img/diwali-2.jpg",
    },
    {
      img: "/img/diwali-3.jpg",
    },
  ];

  const Dinner_images = [
    {
      img: "/img/cristmas-0.jpg",
    },
    {
      img: "/img/cristmas-2.jpg",
    },
    {
      img: "/img/cristmas-3.jpg",
    },
    {
      img: "/img/cristmas-4.jpg",
    },
  ];
  const halloween = [
    {
      img: "/img/halloween-1.jpg",
    },
    {
      img: "/img/halloween-2.jpg",
    },
    {
      img: "/img/halloween-3.jpg",
    },
  ];

  const Republic_Day = [
    {
      img: "/img/26-jan-1.jpg",
    },
    {
      img: "/img/26-jan-2.jpg",
    },
    {
      img: "/img/26-jan-3.jpg",
    },
    {
      img: "/img/26-jan-4.jpg",
    },
  ];
  const Movie_Time = [
    {
      img: "/img/movie_day2.jpg",
    },
    {
      img: "/img/movie_day1.jpg",
    },
    {
      img: "/img/movie_day3.jpg",
    },
    {
      img: "/img/movie_day6.jpg",
    },
    {
      img: "/img/movie_day4.jpg",
    },
  ];


  const Imagicaa_Tour = [
    {
      img: "/img/picnik/tour.jpg",
    },
    {
      img: "/img/picnik/tour-1.jpg",
    },
    {
      img: "/img/picnik/tour-2.jpg",
    },
    {
      img: "/img/picnik/day-1.png",
      playbtn: '/img/play.png'
    },
  ];

  const lonavala = [
    {
      img: "/img/picnik/tour-3.jpg",
    },
    {
      img: "/img/picnik/tour-4.jpg",
    },
    {
      img: "/img/picnik/tour-5.jpg",
    },
    {
      img: "/img/picnik/day-2.png",
      playbtn: '/img/play.png'
    },
  ];

  // const data = [
  //   {
  //     img: "./img/about-1.png",
  //     text: "ANDROID DEVELOPMENT"
  //   },
  //   {
  //     img: "./img/about-2.png",
  //     text: "ANDROID DEVELOPMENT"
  //   },
  //   {
  //     img: "./img/about-1.png",
  //     text: "ANDROID DEVELOPMENT"
  //   },
  //   {
  //     img: "./img/about-2.png",
  //     text: "ANDROID DEVELOPMENT"
  //   },
  //   {
  //     img: "./img/about-1.png",
  //     text: "ANDROID DEVELOPMENT"
  //   },
  //   {
  //     img: "./img/about-2.png",
  //     text: "ANDROID DEVELOPMENT"
  //   }
  // ]

  const handleEvent = (event) => {
    history.push({
      pathname: "/life@7seasolevents",
      search: `?event=${event}`,
    });
  };
  return (
    <>
      {/* <div className="service-bg">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center  flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                <div className=" text-left">
                  <span className="welcome">OUR LIFE</span>
                </div>
                <div>
                  <h1 className="separator_off">
                    Life of 7seasol
                  </h1>
                  <br />
                  <h6 className="subtitle">
                    Website and App development solution for transforming and innovating businesses.
                    and innovating businesses.
                  </h6>
                </div>
              </div>
            </Col>
            <Col>
              <img src="./img/life@7seasol-banner.png" width={"100%"} />
            </Col>
          </Row>
        </Container>
      </div> */}

      <div className="service-bg">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center  flex-column text-left text-white gy-5 header-content"
                style={{ rowGap: "20px" }}
              >
                <div className=" text-left">
                  <span className="welcome">OUR ACTIVITIES</span>
                </div>
                <div>
                  <h1 className="separator_off">Life of 7seasol</h1>
                  
                  <h6 className="subtitle">
                  Apart from work, Our activitties
                  </h6>
                </div>
              </div>
            </Col>
            <Col className="services-img">
              <img src="./img/life@7seasol-banner.png" width="100%" />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <div className="life7seasol">
          <h3 className="text-center">
            We believe in “work smart and play hard” culture. The amusing events
            and activities conducted here on a regular basis are well-liked and
            highly-anticipated.
          </h3>
        </div>
        {/* <div className="main-event"> */}
        <div className="event-section-1">
          <img src="./img/1.png" />
        </div>
        <div className="event-section-2">
          <div className="about-service">
            <div className="title-top">LIFE@7SEASOL</div>
            <h2 className="title">Celebrations and Events</h2>
            <div className="events_cards">
              <div className="d-flex flex-lg-row flex-column">
                <div className="card-img w-50">
                  {/* <div className="set-diwalievent"> */}
                  <Slider {...diwalievent}>
                    {diwali_event_images.map((item) => (
                      <img src={item.img} width="75%" />
                    ))}
                  </Slider>

                  {/* </div> */}
                </div>
                <div className="card-info1 w-50 d-flex justify-content-center flex-column">
                  <h2 className="mb-3 text-center">Diwali Celibration</h2>
                  <p className="text-center">
                    May the supreme light illumine your mind, enlighten your heart and strengthen the human bonds in your homes and communities
                  </p>

                </div>
              </div>
            </div>
            {window.innerWidth > 991 && <hr />}
            <div className="events_cards">
              <div className="d-flex flex-lg-row flex-column-reverse">
                <div className="card-info1 w-50 d-flex justify-content-center flex-column">
                  <h2 className="mb-3 text-center">Dinner Party</h2>
                  <p className="text-center">
                    The best way to end the week is a nice Dinner Party with the 7seasol family!
                    WE all deserve to take a break, relax and rewind. A quiet, simple dinner party of 7seasol

                  </p>
                </div>
                <div className="card-img w-50">
                  {/* <div className="set-diwalievent"> */}
                  <Slider {...diwalievent}>
                    {Dinner_images.map((item) => (
                      <img src={item.img} width="75%" />
                    ))}
                  </Slider>

                  {/* </div> */}
                </div>
              </div>
            </div>
            {window.innerWidth > 991 && <hr />}
            <div className="events_cards">
              <div className="d-flex flex-lg-row flex-column">
                <div className="card-img w-50">
                  {/* <div className="set-diwalievent"> */}
                  <Slider {...diwalievent}>
                    {halloween.map((item) => (
                      <img src={item.img} width="75%" />
                    ))}
                  </Slider>

                  {/* </div> */}
                </div>
                <div className="card-info1 w-50 d-flex justify-content-center flex-column">
                  <h2 className="mb-3 text-center">Halloween</h2>
                  <p className="text-center">
                    We're a little obsessed with our Halloween decorations and celebrations, with tattoo drawing.

                  </p>

                </div>
              </div>
            </div>
            {window.innerWidth > 991 && <hr />}
            <div className="events_cards">
              <div className="d-flex flex-lg-row flex-column flex-column-reverse">
                <div className="card-info1 w-50 d-flex justify-content-center flex-column">
                  <h2 className="mb-3 text-center">26 January</h2>
                  <p className="text-center">
                    Let us make a promise that we would not let the hard sacrifices of our brave freedom fighters go in vain. We would work hard to make our country the best in the world. Happy Republic Day 2022!
                  </p>
                </div>
                <div className="card-img w-50">
                  {/* <div className="set-diwalievent"> */}
                  <Slider {...diwalievent}>
                    {Republic_Day.map((item) => (
                      <img src={item.img} width="75%" />
                    ))}
                  </Slider>

                  {/* </div> */}
                </div>
              </div>
            </div>
            {window.innerWidth > 991 && <hr />}
            <div className="events_cards">
              <div className="d-flex flex-lg-row flex-column">
                <div className="card-img w-50">
                  <Slider {...diwalievent}>
                    {Movie_Time.map((item) => (
                      <img src={item.img} width="75%" />
                    ))}
                  </Slider>
                </div>
                <div className="card-info1 w-50 d-flex justify-content-center flex-column">
                  <h2 className="mb-3 text-center">Movie Time</h2>
                  <p className="text-center">
                    We're a little obsessed with our Halloween decorations and celebrations, with tattoo drawing.
                  </p>
                </div>
              </div>
            </div>
            {window.innerWidth > 991 && <hr />}
            <div className="events_cards">
              <div className="d-flex flex-lg-row flex-column">
                <div className="card-info1 w-50 d-flex justify-content-center flex-column">
                  <h2 className="mb-3 text-center">Imagicaa Tour</h2>
                  <p className="text-center">
                    We're a little obsessed with our Halloween decorations and celebrations, with tattoo drawing.
                  </p>
                </div>
                <div className="card-img w-50 position-relative">
                  <Slider {...diwalievent}>
                    {Imagicaa_Tour.map((item) => (
                      <div className="position-relative video-img" style={{ width: '75%' }}>
                        <img src={item.img} width="100%" />
                        <img className="play-btn" src={item.playbtn} onClick={() => playVideos('day-1')} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            {window.innerWidth > 991 && <hr />}
            <div className="events_cards">
              <div className="d-flex flex-lg-row flex-column">
                <div className="card-img w-50">
                  <Slider {...diwalievent}>
                    {lonavala.map((item) => (
                      <div className="position-relative video-img" style={{ width: '75%' }}>
                        <img src={item.img} width="100%" />
                        <img className="play-btn" src={item.playbtn} onClick={() => playVideos('day-2')} />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="card-info1 w-50 d-flex justify-content-center flex-column">
                  <h2 className="mb-3 text-center">Lonavala Tour</h2>
                  <p className="text-center">
                    We're a little obsessed with our Halloween decorations and celebrations, with tattoo drawing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Container>
      {playVideo &&
        <div className='react-player'>
          <img src={closevideoicon} className="vedio_close_btn" onClick={() => setPlayVideo(false)} />
          <ReactPlayer
            url={videoURL}
            width='100%'
            height='100%'
            controls
            playing={play}
            onEnded={() => {
              setPlay(false);
            }}
          />
        </div>
      }
    </>
  );
};
export default Life7seasol;

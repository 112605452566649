import React, { useEffect, useState } from 'react'
import { Switch } from 'react-router'
import Pages from '../pages'
import Loader from '../reusebleComponents/Loader'
import { useDispatch, useSelector } from "react-redux"
import { setIsLoading } from '../redux/actions/LoadingAction'

const Routes = () => {

    const { is_loading } = useSelector((state) => state.loading)
    const [temp, setTemp] = useState(true)

    const dispatch = useDispatch()
    useEffect(() => {
        if (temp) {

            const state = document.readyState
            if (state === "interactive") {

                dispatch(setIsLoading(true))
            }
            if (state === 'complete') {
                dispatch(setIsLoading(false))
                setTemp(false)
            }
         
        }
    })
    return (
        <>
            {
                is_loading ? <Loader /> :

                    <Switch>
                        <Pages />
                    </Switch>}
        </>
    )
}

export default Routes

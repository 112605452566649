import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const IosDevelopment = () => {
    return (
        <>
            <div className="services-bg">
      <Container>
        <Row>
        <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center header-content flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                {/* <div className=" text-left">
                  <span className="welcome">IOS APPLICATION</span>
                </div> */}
                <div>
                  <h1 className="separator_off">
                  IOS APPLICATION
                  </h1>
                  <br />
                  {/* <h6 className="subtitle">
                  Website and App development solution for transforming and innovating businesses.
                  and innovating businesses.
                  </h6> */}
                </div>
              </div>
            </Col>  
          {/* <Col>
          <div className="android-img services-inner-img">
                <img src="./img/IOS.png" width={"90%"} />
          </div>
          </Col> */}
        </Row>
        </Container>
    </div>
            <Container>
                <Row>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-img services-inner-img">
                            <img src="./img/IOS.png" alt="" />
                        </div>
                    </Col>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-text">
                            <h2>IOS APPLICATION DEVELOPMENT</h2>
                            <p>At 7SEASOL, we build a diverse range of iPhone applications.Our iPhone Application Development team innovates with applications that are creative, flexible and have high usability quotient.</p>
                            <ul>How We do it?</ul>
                            <li>- Custom iPhone App Development</li>
                            <li>- Native iPhone App Development</li>
                            <li>- iPhone App Upgrade</li>
                            <li>- Hybrid iPhone App Development</li>
                            <li>- iPhone App UI/UX Designing</li>
                            <li>- iPhone App Testing/Portability</li>
                        </div>
                        <div className="android-text">
                            <ul>Our Source</ul>
                            <li>xocde</li>
                            <li>Objective - C</li>
                            <li>Swift</li>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default IosDevelopment

import React from 'react'
import { Switch, Route } from 'react-router'
import AndroidDevelopment from './service/component/AndroidDevelopment'
import GameDevelopment from './service/component/GameDevelopment'
import IosDevelopment from './service/component/IosDevelopment'
import SEODevelopment from './service/component/SEODevelopment'
import WebAndAppdesign from './service/component/WebAndAppdesign'
import WebDevelopment from './service/component/WebDevelopment'
import About from './about/About'
import Contact from './contact/Contact'
import Home from './home/Home'
import Layout from './layout/Layout'
import Service from './service/Service'
import Hiringdeveloper from './hiringDeveloper/Hiringdeveloper'
import Portfolio from './ourportfolio/Portfolio'
import Applyforjob from './applyforjob/Applyforjob'
import Life7Seasol from './life7seasol/Life7seasol'
import Life7seasolcomponent from './life7seasol/Life7seasolcomponent'

const Index = () => {
    return (
        <Switch>
            <Layout>
                <Route exact path="/" component={Home} />
                <Route exact path="/service" component={Service} />
                <Route exact path="/about" component={About} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/android-development" component={AndroidDevelopment} />
                <Route exact path="/game-development" component={GameDevelopment} />
                <Route exact path="/ios-development" component={IosDevelopment} />
                <Route exact path="/seo-development" component={SEODevelopment} />
                <Route exact path="/web-and-app-design" component={WebAndAppdesign} />
                <Route exact path="/web-development" component={WebDevelopment} />
                <Route exact path="/hiring-developer" component={Hiringdeveloper} />
                <Route exact path="/our-portfolio" component={Portfolio} />
                <Route exact path="/applyforjob" component={Applyforjob} />
                <Route exact path="/life@7seasol" component={Life7Seasol} />
                <Route exact path="/life@7seasolevents" component={Life7seasolcomponent} />
            </Layout>
        </Switch>
    )
}

export default Index

import { IS_LOADING, IS_OPEN } from "../types";

export const setIsLoading = (value) => (dispatch) => {
  dispatch({
    type: IS_LOADING,
    payload: value,
  });
};

export const sidebarAct = (value) => (dispatch) => {
  dispatch({
    type: IS_OPEN,
    payload: value,
  });
};

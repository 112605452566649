import React from "react";

const Counter = () => {
  return (
    <>
    
      <div className="counter">
        <div class="container">
        <div className="title-top our-strangth margin-left-right ">OUR STRANGTHS</div>
          <div class="row">
            <div class="col-md-3 col-sm-6 counter-info">
              <h1>
                <span class="counter">150+</span>
              </h1>
              <h3>Satisfied Clients</h3>
            <img src="img/smile.png"></img>
            </div>
            <div class="col-md-3 col-sm-6 counter-info">
              <h1>
                <span class="counter">90+</span>
              </h1>
              <h3>Project Completed</h3>
              <img src="img/projection.png"></img>
            </div>
            <div class="col-md-3 col-sm-6 counter-info">
              <h1>
                <span class="counter">95+</span>
              </h1>
              <h3>Project Lunched</h3>
              <img src="img/startup (1).png"></img>
            </div>
            <div class="col-md-3 col-sm-6 counter-info">
              <h1>
                <span class="counter">4</span>
              </h1>
              <h3>Years Completed</h3>
              <img src="img/celebration.png"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Counter;

import React from 'react'
import { Row } from 'react-bootstrap'

const Uiux = () => {

    const card = [
        {
            img: "./img/portfolio_UI (1).jpg",
            text: "Expence Manager"
        },
        {
            img: "./img/portfolio_UI (2).jpg",
            text: "Food Delivery App"
        },
        {
            img: "./img/portfolio_UI (3).jpg",
            text: "Hotel Booking"
        },
        {
            img: "./img/portfolio_UI (4).jpg",
            text: "Messanger"
        },
        {
            img: "./img/portfolio_UI (5).jpg",
            text: "Calender App"
        },
        {
            img: "./img/portfolio_UI (6).jpg",
            text: "Notistar"
        }
    ]

    return (
        <>
                <Row>
            <div className="tab-content">
                {card.map((item) =>
                    <div class="tab-card">
                        <div className="tab-img"><img src={item.img} alt="" /></div>
                        <h5>{item.text}</h5>
                    </div>
                )}
            </div>
                </Row>
        </>
    )
}

export default Uiux

import { IS_OPEN } from "../types";

const initialState = {
  is_open: "hide-sidebar",
};

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_OPEN:
      return {
        ...state,
        is_open: action.payload,
      };
    default:
      return state;
  }
};

export default sidebarReducer
import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const sidebar = useSelector((state) => state.sidebar);
  return (
    <>
      <div className={`sidebar ${sidebar.is_open }`}>
      <Nav className="nav-Links d-flex flex-column" style={{marginTop:"70px"}}>
            <Link to="/" className="nav-link">
              Home
            </Link>
            <Link to="/service" className="nav-link">
              Service
            </Link>
            <Link to="/our-portfolio" className="nav-link">
              Portfolio
            </Link>
            <Link to="/applyforjob" className="nav-link">
              Apply For Job
            </Link>
            <Link to="/life@7seasol" className="nav-link">
              Life@7Seasol
            </Link>
            <Link to="/about" className="nav-link">
              About
            </Link>
          </Nav>
      </div>
    </>
  );
};

export default Sidebar;

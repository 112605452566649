import React, { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../life7seasol/jq";

const Footer = (props) => {
  return (
    <>
      <div className="footer-section">
        <Container>
          <Row className="footer-content">
            <Col lg={3} md={6} sm={6} className="footer-logo">
              <img src="./img/7seasol_white_logo.svg" alt="" />
              <div className="">
                <p>
                  Are you looking for ecommerce website and taking some IT
                  advice and guide then we have a right choice for you..!
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} className="img-content footer_links usefull-link">
              <h3>Site Map</h3>
              <Link to="/" className="link-contant">
                <p>Home</p>
              </Link>
              <Link to="/service" className="link-contant">
                <p>Services</p>
              </Link>
              <Link to="/about" className="link-contant">
                <p>About</p>
              </Link>
              <Link to="/contact" className="link-contant">
                <p>Contact Us</p>
              </Link>
            </Col>
            <Col lg={3} md={6} sm={6} className="footer_links img-content">
              <h3>Our Services</h3>
              <Link to="/android-development" className="link-contant">
                <p>Android App Development</p>
              </Link>
              <Link to="/ios-development" className="link-contant">
                <p>Ios App Development</p>
              </Link>
              <Link to="/web-development" className="link-contant">
                <p>Web Development</p>
              </Link>
              <Link to="/game-development" className="link-contant">
                <p>Unity Game Development</p>
              </Link>
              <Link to="/web-and-app-design" className="link-contant">
                <p>UI/UX Design</p>
              </Link>
              <Link to="/seo-development" className="link-contant">
                <p>SEO / ASO</p>
              </Link>
            </Col>
            <Col lg={3} md={6} sm={6} className="img-content">
              <h3>Contact Us</h3>
              <p>
                <b>Location : </b>2080, Silver Business Point, VIP Circle,
                Uttran, SURAT-394105
              </p>
              <p>
                <span className="address-email">Tel:</span> +91 8200245456
              </p>
              <p>
                {" "}
                <span className="address-email">Email:</span> info@7seasol.com
              </p>
              <div className="connect-logos">
                {/* <img src="./img/facebook.png" alt="" className="facebook-logo" />
                <img src="./img/twitter.png" alt="" className="facebook-logo" /> */}
                {/* <a
                  href="https://www.linkedin.com/company/7seasol"
                  target="_blank"
                > */}
                  <img
                    src="./img/linkedin.png"
                    alt=""
                    className="facebook-logo"
                    onClick={() => {
                      window.open("https://www.linkedin.com/company/7seasol", "_blank")
                    }}
                  />
                {/* </a> */}
                {/* <a
                  href="https://www.google.com/search?q=7seasol&rlz=1C1CHBF_enIN926IN926&oq=7seasol&aqs=chrome.0.69i59j35i39j69i60j69i61j69i60l4.1652j0j7&sourceid=chrome&ie=UTF-8"
                  target="_blank"
                > */}
                  <img
                    src="./img/google.png"
                    alt=""
                    className="facebook-logo"
                    onClick={() => {
                      window.open("https://www.google.com/search?q=7seasol&rlz=1C1CHBF_enIN926IN926&oq=7seasol&aqs=chrome.0.69i59j35i39j69i60j69i61j69i60l4.1652j0j7&sourceid=chrome&ie=UTF-8", "_blank")
                    }}
                  />
                {/* </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;

import React from 'react'

const Loader = () => {
    return (
        <>
            <div className="loader">
                <div className="loader-bg">
                    <div className="loadercontent"></div>
                </div>
                <div className="loadercontent-right"></div>
                <div className="loadercontent-left"></div>
            </div>
        </>
    )
}

export default Loader

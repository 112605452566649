import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const About = () => {
  return (
    <>
      <div className="services-bg">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center  header-content flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                {/* <div className=" text-left">
                  <span className="welcome">ABOUT US</span>
                </div> */}
                <div>
                  <h1 className="separator_off">Welcome to 7seasol</h1>
                  {/* <h6 className="subtitle">
                   Welcome to 7seasol
                  </h6> */}
                </div>
              </div>
            </Col>
            {/* <Col className="services-img">
              <img src="./img/about-banner.png" width={"100%"} />
            </Col> */}
          </Row>
        </Container>
      </div>
      <Container className="">
        {/* <Row>
                    <Col className="img-content">
                        <h3>7SEASOL</h3>
                        <p>is IT Service company which established in 2018.Our company provide services of website designing, logo development, digital marketing, game development and many more. If you are searching for superior, dedicated and innovative technology served in applications and websites then you are at your destination. We serves the best of us to our clients. Where the technology feels delighted, for sure there employees are inventive, ingenious and imaginative. Our employees are “The Beats” of the company. The values of our company are collaboration, integrity and accountability which committed in our heart and mind.</p>
                    </Col>
                    <Col className="about-imp">
                        <img src="./img/about-1.png" />
                    </Col>
                </Row>
                <div className="border-content"></div>
                <Row>
                    <Col className="about-imp">
                        <img src="./img/about-2.png" />
                    </Col>
                    <Col className="img-content">
                        <h3>Our vision</h3>
                        <ol className="img-content">
                            <li>To be leader in providing qualitative and innovative services and solutions by connecting corporate houses and individual by Networking in Excellence</li>
                            <li>Talk business with business people</li>
                            <li> Identify top IT professionals for the job</li>
                            <li>Manage people and projects</li>
                        </ol>
                    </Col>
                </Row>
                <div className="border-content"></div>
                <Row>
                    <Col className="img-content">
                        <h3>Why do you have go to with us?</h3>
                        <p>We will be the Information Technology Service Provider of choice for customers worldwide by leveraging knowledge in the area of new technologies thereby impacting business results. We emphasize on the empowerment of the individual to make decisions in the best interests of client.</p>
                        <p> <span className="address-email">Email:</span> info@7seasol.com</p>
                        <p><span className="address-email">Phone:</span> +91 8200245456</p>
                        <div className="connect-logos">
                            <img src="./img/facebook.png" alt="" className="facebook-logo" />
                            <img src="./img/twitter.png" alt="" className="facebook-logo" />
                            <img src="./img/linkedin.png" alt="" className="facebook-logo" />
                            <img src="./img/google.png" alt="" className="facebook-logo" />
                        </div>
                    </Col>
                    <Col className="about-imp">
                        <img src="./img/about-3.png" />
                    </Col>
                </Row> */}
        <div className="about-content-row">
          <div className="">
            <h3 className="title-theme-head">7SEASOL</h3>
            <p className="title-theme-p">
              is IT Service company which established in 2018.Our company
              provide services of website designing, logo development, digital
              marketing, game development and many more. If you are searching
              for superior, dedicated and innovative technology served in
              applications and websites then you are at your destination. We
              serves the best of us to our clients. Where the technology feels
              delighted, for sure there employees are inventive, ingenious and
              imaginative. Our employees are “The Beats” of the company. The
              values of our company are collaboration, integrity and
              accountability which committed in our heart and mind.
            </p>
          </div>
          <div className="about-imp ml-20">
            <img src="./img/about-2.png" alt="" />
          </div>
        </div>
        <div className="border-content"></div>
        <div className="about-content-row-2">
          <div className="about-imp">
            <img src="./img/41.jpg" alt="" />
          </div>
          <div className=" ml-20">
            <h3 className="title-theme-head">Our vision</h3>
            <ol className="img-content ">
              <li>
                To be leader in providing qualitative and innovative services
                and solutions by connecting corporate houses and individual by
                Networking in Excellence
              </li>
              <li>Talk business with business people</li>
              <li> Identify top IT professionals for the job</li>
              <li>Manage people and projects</li>
            </ol>
          </div>
        </div>
        <div className="border-content"></div>
        <div className="about-content-row">
          <div className="">
            <h3 className="title-theme-head">Why do you have go to with us?</h3>
            <p className="title-theme-p">
              We will be the Information Technology Service Provider of choice
              for customers worldwide by leveraging knowledge in the area of new
              technologies thereby impacting business results. We emphasize on
              the empowerment of the individual to make decisions in the best
              interests of client.
            </p>
            <p className="title-theme-p">
              {" "}
              <span className="address-email">Email:</span> info@7seasol.com
            </p>
            <p className="title-theme-p">
              <span className="address-email">Phone:</span> +91 8200245456
            </p>
            <div className="connect-logos">
              {/* <img src="./img/facebook.png" alt="" className="facebook-logo" />
                            <img src="./img/twitter.png" alt="" className="facebook-logo" /> */}
              {/* <a
                href="https://www.linkedin.com/company/7seasol"
                target="_blank"
              > */}
                <img
                  src="./img/linkedin.png"
                  alt=""
                  className="facebook-logo"
                  onClick={() => {
                    window.open("https://www.linkedin.com/company/7seasol", "_blank")
                  }}
                />
              {/* </a> */}
              {/* <a
                href="https://www.google.com/search?q=7seasol&rlz=1C1CHBF_enIN926IN926&oq=7seasol&aqs=chrome.0.69i59j35i39j69i60j69i61j69i60l4.1652j0j7&sourceid=chrome&ie=UTF-8"
                target="_blank"
              > */}
                <img src="./img/google.png" alt="" className="facebook-logo"
                 onClick={() => {
                    window.open("https://www.google.com/search?q=7seasol&rlz=1C1CHBF_enIN926IN926&oq=7seasol&aqs=chrome.0.69i59j35i39j69i60j69i61j69i60l4.1652j0j7&sourceid=chrome&ie=UTF-8", "_blank")
                  }}
                />
              {/* </a> */}
            </div>
          </div>
          <div className="about-imp">
            <img src="./img/about-3.png" alt="" />
          </div>
        </div>
      </Container>
    </>
  );
};

export default About;

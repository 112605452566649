import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AndroidDevelopment = () => {
  return (
    <>
      <div className="services-bg">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center header-content flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                {/* <div className=" text-left">
                  <span className="welcome">ANDROID APPLICATION</span>
                </div> */}
                <div>
                  <h1 className="separator_off">ANDROID APPLICATION</h1>
                  <br />
                  {/* <h6 className="subtitle">
                    Website and App development solution for transforming and
                    innovating businesses. and innovating businesses.
                  </h6> */}
                </div>
              </div>
            </Col>
            {/* <Col>
              <div className="android-img services-inner-img">
                <img src="./img/ANDROID-DEVELOPMENT.png" width={"90%"} />
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={12} lg={12} xl={6}>
            <div className="android-img services-inner-img">
              <img src="./img/ANDROID-DEVELOPMENT.png" alt="" />
            </div>
          </Col>
          <Col md={12} lg={12} xl={6}>
            <div className="android-text">
              <h2>ANDROID APPLICATION</h2>
              <p>
                7SEASOL offer professional and reliable Android application
                development services that cater to diverse client needs and
                business objectives. Tailored to meet client requirements, we
                design, develop, test, upgrade, implement, and support
                customized Android application with outstanding utility and
                superior performance.
                <br />
                <br />
                Our dedicated team of mobile application developers has keen
                knowledge and skilled expertise in Android platform that can be
                seamlessly adapted and integrated across multiple platforms and
                conventional smartphone architectures. In addition to this, our
                customized Android application development services ranges
                widely from advertising to multimedia applications, asset
                tracking to messaging to voicing, gaming to GPS systems, and
                much more.
              </p>
              <ul>How We do it?</ul>
              <li>- Perceiving your ideas and insights</li>
              <li>- Brainstorming with our experts</li>
              <li>- Creating your masterpiece design</li>
              <li>- Coding and compiling</li>
              <li>- Testing your app/web</li>
              <li>- Launching your bubble</li>
            </div>
            <div className="android-text">
                            <ul>Our Source</ul>
                            <li>java</li>
                            <li>React Native</li>
                            <li>kotlin</li>
                            <li>SDK</li>
                            <li>Android Studio</li>
                        </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AndroidDevelopment;

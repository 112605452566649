import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const SEODevelopment = () => {
    return (
        <>
            <div className="services-bg">
      <Container>
        <Row>
        <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center header-content flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                {/* <div className=" text-left">
                  <span className="welcome">SEO & MARKETING</span>
                </div> */}
                <div>
                  <h1 className="separator_off">
                  SEO & MARKETING
                  </h1>
                  {/* <br /> */}
                  {/* <h6 className="subtitle">
                  Website and App development solution for transforming and innovating businesses.
                  and innovating businesses.
                  </h6> */}
                </div>
              </div>
            </Col>  
          {/* <Col>
          <div className="android-img services-inner-img">
                <img src="./img/SEO1.png" width={"90%"} />
          </div>
          </Col> */}
        </Row>
        </Container>
    </div>
            <Container>
                <Row>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-img services-inner-img">
                            <img src="./img/SEO1.png" alt="" />
                        </div>
                    </Col>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-text">
                            <h2>SEO</h2>
                            <p>We have a senior SEO consultant run a full analysis of your site to determine how you are currently being perceived by the Search Engines.<br /><br />This allows us to determine what needs to be optimized and what SEO techniques need to be applied. Based on their findings, the keyword research, and competitive analysis a strategy is created and is implemented by SearchEnvision</p>
                        </div>
                        <div className="android-text">
                            <ul>Our Source</ul>
                            <li> SOCIAL MEDIA E NGAGEMENT</li>
                            <li> SOCIAL BOOKMARKING</li>
                            <li>FORUM SUBMISSION</li>
                            <li>ARTICLE SUBMISSION</li>
                            <li>QUORA QUESTION AND ANSER</li>
                            <li>VIDEO SUBMISSION</li>
                            <li>IMAGE SUBMISSION</li>
                            <li>PDF & PPT SUBMISSION</li>
                            <li> GUEST POST</li>
                            <li> OUTREACH POST</li>
                        </div>
                        <div className="android-text">
                            <ul>Our Source</ul>
                            <li>Social Bookmarking</li>
                            <li>Guest Post</li>
                            <li>Outreach Post</li>
                            <li>Video Submission</li>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SEODevelopment

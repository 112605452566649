import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ANDRPID from "../img/ICON/ANDRPID.png";
import GAME from "../img/ICON/GAME.png";
import ISO from "../img/ICON/IOS.png";
import SEO from "../img/ICON/SEO.png";
import WEBDESIGN from "../img/ICON/WEB-DESIGN.png";
import WEB from "../img/ICON/WEB.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ServicesSlider = () => {
  const servicesslidercard = [
    {
      img: ANDRPID,
      title: "Android Development",
      text: "Our 12 expert android developers serve extreme quality in work and efficiency. We offers faster & relatively low-cost development for new startups.",
    },
    {
      img: GAME,
      title: "Game Development",
      text: "We have very agile method about game development having process with Requirement Gathering, UI/UX Design, Development Stage, Quality Check and Support & Maintenance.",
    },
    {
      img: ISO,
      title: "IOS Development",
      text: "We have extremely experienced IOS development team specializes in producing custom &amp; secure iPhone app solutions.",
    },
    {
      img: SEO,
      title: "SEO Development",
      text: "We have a senior SEO consultant run a full analysis of your site to determine how you are currently being perceived by the Search Engines.",
    },
    {
      img: WEBDESIGN,
      title: "Web Design",
      text: "Our team of expert designers will deliver strong and successful mobile app design, website designs and game design.",
    },
    {
      img: WEB,
      title: "Web Development",
      text: "We have a senior SEO consultant run a full analysis of your site to determine how you are currently being perceived by the Search Engines.",
    },
  ];

  const servicesslid = {
    dots: true,
    autoplay: true,
    speed: 300,
    arrows: false,
    Array: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          speed: 300,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          speed: 300,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <>

      <div className="our-services">
        <Container>
          <div className="title-top">OUR SERVICES</div>
          <h2 className="title">
            Leaders in Web and Mobile <br />
            Devlopment Services
          </h2>
          {/* <p className="text ">
            Approximately 15 million verified users, 200 institutions, and
            600 ecosystem partners in over <br /> 55 countries trust Sway to
            easily and securely invest, save, earn, and use crypto.
          </p> */}
          <div className="slider">
            <Slider {...servicesslid}>
              {servicesslidercard.map((diteal) => (
                <div className="services-slider services-slider-card">
                  <img src={diteal.img} alt="" />
                  <h2>{diteal.title}</h2>
                  <p className="services-slider-p">{diteal.text}</p>
                  <button className="btn-join-now">Join Now</button>
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ServicesSlider;

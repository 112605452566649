import { applyMiddleware, combineReducers } from "redux";
import { createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';

import { LoadingReducer } from "./reducer/LoadingReducer";
import sidebarReducer from "./reducer/sidebarReducer";

const middleware = [thunk];

const rootReducer = combineReducers({
    loading: LoadingReducer,
    sidebar: sidebarReducer

});
const store = createStore(
    rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
  );

export default store;

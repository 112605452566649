import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const GameDevelopment = () => {
    return (
        <>
            <div className="services-bg">
      <Container>
        <Row>
        <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center header-content flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                {/* <div className=" text-left">
                  <span className="welcome">GAME DEVELOPMENT</span>
                </div> */}
                <div>
                  <h1 className="separator_off">
                  GAME DEVELOPMENT
                  </h1>
                  <br />
                  {/* <h6 className="subtitle">
                  Website and App development solution for transforming and innovating businesses.
                  and innovating businesses.
                  </h6> */}
                </div>
              </div>
            </Col>  
          {/* <Col>
          <div className='services-inner-img android-img' >
          <img className='services-inner-img' src="./img/GAME DEVELOPMENT.png" width={"90%"}/>
          </div>
          </Col> */}
        </Row>
        </Container>
    </div>
            <Container>
                <Row>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-img services-inner-img">
                            <img src="./img/GAME DEVELOPMENT.pNg" alt="" />
                        </div>
                    </Col>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-text">
                            <h2>GAME DEVELOPMENT</h2>
                            <p>Unity 3D Mobile game development has gone main stream with the unprecedented success and has become a lucrative business that’s generating a million dollars in revenue. Unity 3D games are well known to provide a perfect solution on how to spend leisure time not only to kids, but also to the elderly. The winning outcomes are not because the businesses have realized the tremendous potential, but the range of mobile game development tools has also made the equal contribution. Unity 3D is an integrated authoring tool for creating 3D video games or other interactive content such as dynamic fonts, architectural visualizations or real-time 3D animations. It can be used for the iPhone games as well as other platforms such as Google Android.<br /><br />Unity 3D Game development is actually founded on the use of the multi-platform Unity game engine. Basically, the game engine allows developers to make video games for different platforms, including mobile devices, consoles and even desktop applications. You can pursue Unity 3D Game development if you want to take advantage of the fact that it is supported on various common platforms – such as Wii U, Windows Phone 8, Xbox 360, PlayStation 3, Flash, Linux, OS X, Blackberry, Windows, Android, and iOS and web browsers.<br /><br />One reason you may need expert assistance is that not everyone has the talents to cover all aspects of designing a game. Some people in your team may be good in the artistic aspect meaning creating the game concept, fleshing out the script and the character profile, while others can be good at determining the logical game play progression. By hiring us you will be able to get your unique Unity 3D Game up and running in no time so that your target market will pay attention and start playing.<br /><br /></p>
                        </div>
                        <div className="android-text">
                            <ul>Our Source</ul>
                            <li>C#</li>
                            <li>Unity 3d enegin </li>
                            <li>SDK</li>
                            <li>Android</li>
                            <li>IOS</li>
                            <li>Desktop Games</li>
                            <li>Console (xbox,PlayStation)</li>
                            <li>AR,VR,MR</li>
                            <li>WebGL</li>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default GameDevelopment

// import Button from '@restart/ui/esm/Button'
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  const location = window.location.pathname;
  const [scroll, setScroll] = useState(0);

  window.addEventListener("scroll", () => {
    setScroll(window.pageYOffset);
  });
  
  window.addEventListener("click", (e) => {
    e.preventDefault()
    e.stopPropagation()
    const isShow = document.getElementsByClassName('navbar-collapse')[0].classList.contains('show')
    if(isShow) {
      document.getElementById('toggle-btn').style.display = "block"
      document.getElementById('toggle-btn').classList.add('collapsed')
      document.getElementById('toggle-btn-close').style.display = "none"
      document.getElementById('toggle-btn-close').classList.remove('collapsed')
      document.getElementsByClassName('navbar-collapse')[0].classList.remove('show')
    }
  });


  useEffect(() => {
    document.getElementById("toggle-btn").onclick = function(e){
      e.preventDefault()
      e.stopPropagation()
      document.getElementById('toggle-btn').style.display = "none"
      document.getElementById('toggle-btn').classList.remove('collapsed')
      document.getElementById('toggle-btn-close').style.display = "block"
      document.getElementsByClassName('navbar-collapse')[0].classList.add('show')
      document.getElementById('toggle-btn-close').classList.add('collapsed')
    };
  })
  
  useEffect(() => {
    document.getElementById('toggle-btn-close').style.display = "none"
  }, [])

  return (
    <>

      {/* <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Features</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="#deets">More deets</Nav.Link>
              <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      <div className={`${scroll > 10 ? "header-shadow" : ""} header`}>
        <Container>
          <Navbar
            collapseOnSelect={true}
            expand="xl"
            variant="light"
            className="d-flex justify-content-between"
          >
            <Navbar.Brand className="logo-text m-0">
              <Link to="/">
                <img
                  src="./img/7seasol_white_logo.svg"
                  alt=""
                  className="logo-img"
                />
              </Link>
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="toggle-btn"
              id="toggle-btn"
            />
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="toggle-btn-close"
              id="toggle-btn-close"
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="d-flex- justify-content-between"
            >
              <Nav className="nav-Links">
                <Link
                  to="/"
                  // className="nav-link"
                  className={location === "/" ? "active-link" : "nav-link"}
                >
                  Home
                </Link>
                <Link
                  to="/service"
                  // className="nav-link"
                  className={
                    location === "/service" ? "active-link" : "nav-link"
                  }
                >
                  Service
                </Link>
                <Link
                  to="/our-portfolio"
                  className={
                    location === "/our-portfolio" ? "active-link" : "nav-link"
                  }
                >
                  Portfolio
                </Link>
                <Link
                  to="/applyforjob"
                  className={
                    location === "/applyforjob" ? "active-link" : "nav-link"
                  }
                >
                  Apply For Job
                </Link>
                <Link
                  to="/life@7seasol"
                  className={
                    location === "/life@7seasol" ? "active-link" : "nav-link"
                  }
                >
                  Life@7Seasol
                </Link>
                <Link
                  to="/about"
                  className={location === "/about" ? "active-link" : "nav-link"}
                >
                  About
                </Link>
                <Link
                  to="/contact"
                  className={
                    location === "/contact" ? "active-link" : "nav-link"
                  }
                >
                  Contact
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  );
};

export default Header;

import React from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import Slider from "react-slick";
import Cards from "../../components/Cards";
import Ourfetures from "../../components/About_us";
import ServicesSlider from "../../components/ServiceSlider";
import Counter from "../../components/Counter";
import Fetures from "../../components/Fetures";

const imgs = [
  {
    image: "./img/test.png",
  },
  {
    image: "./img/Technology/Android_skill.png",
  },
  {
    image: "./img/Technology/Angular_skill.png",
  },
  {
    image: "./img/Technology/AWS_skill.png",
  },
  {
    image: "./img/Technology/flutter_skill.png",
  },
  {
    image: "./img/Technology/react_skill.png",
  },
  {
    image: "./img/Technology/ios_skill.png",
  },
  {
    image: "./img/Technology/java_skill.png",
  },
  {
    image: "./img/Technology/node_skill.png",
  },
  {
    image: "./img/Technology/ui_skill.png",
  },
  {
    image: "./img/Technology/vuejs_skill.png",
  },
];

const Home = () => {
  // const [count, setCount] = useState(0);
  // console.log(imgs.length);
  // const handlePrev = () => {
  //   if (count < 1) {
  //     setCount(5);
  //   } else {
  //     setCount(count - 1);
  //   }
  // };
  // const handleNext = () => {   
  //   if (count > 4) {
  //     setCount(0);
  //   } else {
  //     setCount(count + 1);
  //   }
  // };

  const skilllogos = {
    // dots: false,
    // infinite: true,
    // autoplay: true,
    // autoplaySpeed: 0,
    // speed: 6000,
    // slidesToShow: 6,
    // slidesToScroll: 6,
    slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  speed: 5000,
  arrows:false,
  pauseOnHover: false,
  cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <>
      <div className="bg-home">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center flex-column text-left text-white gy-5 header-content"
                style={{ rowGap: "20px" }}
              >
                <div className="">
                  <span className="welcome">WE ARE THE BEST</span>
                </div>
                <header>
                  <h1 className="separator_off">
                    We Provide Software Solution For You
                  </h1>
                  <h6 className="subtitle">
                  Step-up progress by activity People, Business & Technology                  </h6>
                  {/* <button className="btn-join-now">Read More</button> */}
                </header>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <img
                className="img-fluid"
                src="./img/banner-image.png"
                alt="image"
              />
            </Col>
          </Row>
        </Container>
        <div className="brands_container">
          <Container>
            <Slider {...skilllogos}>
              {imgs.map((img) => (
                <img
                  src={img.image}
                  alt=""
                  width="100px"
                  className="skilslid"
                />
              ))}
            </Slider>
          </Container>
        </div>
      </div>
      <ServicesSlider />
      {/* <Ourfetures /> */}
      <Counter />
      <Fetures />
      {/* <Cards /> */}
    </>
  );
};

export default Home;

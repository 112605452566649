import React from 'react';
import { Form } from 'react-bootstrap';

const InputField = ({ name, maxLength, value, lablestyleClass, InputstyleClass, onChange, disabled, label, placeholder, type, fromrowStyleclass, rows, accept }) => {


    const handleChange = (event) => {
        // const { value } = event.target;
        // setError(validateInput(validators, value));
        onChange(event);
    }

    return (
        <Form.Group className={`mb-0 ${fromrowStyleclass}`}>
            {label && <Form.Label className={lablestyleClass}>{label}</Form.Label>}

            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={rows ? rows : 5}
                    name={name}
                    className={InputstyleClass}
                    placeholder={placeholder}
                    onChange={(e) => handleChange(e)}
                    value={value}
                    disabled={disabled ? disabled : false}
                    autoComplete="off"
                    maxLength={maxLength}
                />
            ) : (
                <>
                    {type === "file" ? (
                        <Form.Control
                            name={name}
                            className={InputstyleClass}
                            type={"file"}
                            placeholder={placeholder}
                            onChange={(e) => onChange(e)}
                            disabled={disabled ? disabled : false}
                            accept={accept}
                        />
                    ) : (
                        <Form.Control
                            name={name}
                            value={value}
                            className={InputstyleClass}
                            type={type ? type : "text"}
                            placeholder={placeholder}
                            onChange={(e) => onChange(e)}
                            autoComplete="off"
                            disabled={disabled ? disabled : false}
                            maxLength={maxLength}
                        />
                    )}
                </>
            )}


        </Form.Group>
    )
};


export default InputField;

import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Applyforjobmodel from "../models/Applyforjobmodel";

const Applyforjob = () => {
  const [applyForJob, setApplyForJob] = useState(false);

  return (
    <>
      {/* <div className="service-bg">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center  flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                <div className=" text-left">
                  <span className="welcome">APPLY JOB</span>
                </div>
                <div>
                  <h1 className="separator_off">Apply For Job</h1>
                  <br />
                  <h6 className="subtitle">
                    Website and App development solution for transforming and
                    innovating businesses. and innovating businesses.
                  </h6>
                </div>
              </div>
            </Col>
            <Col>
              <img src="./img/applyjob-banner.png" width={"100%"} />
            </Col>
          </Row>
        </Container>
      </div> */}



      <div className="service-bg">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center  flex-column text-left text-white gy-5 header-content"
                style={{ rowGap: "20px" }}
              >
                <div className=" text-left">
                  <span className="welcome" style={{cursor:'pointer'}} onClick={() => setApplyForJob(true)}>APPLY JOB</span>
                </div>
                <div>
                  <h1 className="separator_off">
                  Apply For Job
                  </h1>
                  
                  <h6 className="subtitle">
                  Let's work together and explore opportunities
                  </h6>
                </div>
              </div>
            </Col>
            <Col className="services-img">
              <img src="./img/Applyjob-banner.png" width="100%" />
            </Col>
          </Row>
        </Container>
      </div>



      <Container>
        {/* <div className="applyforjob-contant">
                    <h2>Embark on an accelerating career and become the essential part of our momentous journey</h2>
                    <p>In pursuit of a skyrocketing career where there is no looking back? Become to see your dreams turning into you a reality. Flaunt your talent and show us your expertise. Allow us in shaping your career and your minds towards the right and progressive track. At JKSOL, we offer a flexible, burgeoning and refreshing work environment. With a never-ending growth opportunity, great co-workers and transparency at work, we will knit you the dream company you always wanted to work.</p>
                    <p>We believe in offering state-of-art services to our clients and hence, emphasize on finding the right candidates, who approach their work with complete knowledge, innovation and passion.</p>
                    <p>Does it resembles with your genes? If positive, please apply in-case any of the openings is your-kind. <br /> Kindly, E-Mail your CV at info@7seasol.com 8200245456</p>
                    <button className="contact-send-btn" onClick={() => setApplyForJob(true)}>Apply For Job</button>
                </div> */}
        {
          <div className="applyjob">
            <Row>
              <Col lg={6} sm={12}>
                <div className="">
                  <h2 className="title text-start">
                    Embark on an accelerating career and become the essential
                    part of our momentous journey
                  </h2>
                  <p className="text text-start m-0">
                    In pursuit of a skyrocketing career where there is no
                    looking back? Become to see your dreams turning into you a
                    reality. Flaunt your talent and show us your expertise.
                    <br/>
                    <br/>
                    Allow us in shaping your career and your minds towards the
                    right and progressive track. At 7SEASOL, we offer a flexible,
                    burgeoning and refreshing work environment. With a
                    never-ending growth opportunity, great co-workers and
                    transparency at work, we will knit you the dream company you
                    always wanted to work.
                  </p>
                  <br/>
                  <p className="text text-start m-0">
                    We believe in offering state-of-art services to our clients
                    and hence, emphasize on finding the right candidates, who
                    approach their work with complete knowledge, innovation and
                    passion.
                  </p>
                  <br/>
                  <p className="text text-start m-0">
                    Does it resembles with your genes? If positive, please apply
                    in-case any of the openings is your-kind. <br /> 
                    <br/>
                    Kindly,
                    E-Mail your CV at info@7seasol.com 8200245456
                  </p>
                  
                </div>
              </Col>
              <Col lg={6} sm={12} className="apply-job-announce">
                <img src="./img/apply-job.png"></img>
              </Col>
            </Row>
          </div>
        }
        <div className="text-center">
        <button
                    className="btn-join-now mt-3"
                    onClick={() => setApplyForJob(true)}
                  >
                    Apply For Job
                  </button>
        </div>
      </Container>
      {applyForJob && (
        <Applyforjobmodel
          show={applyForJob}
          onHide={() => setApplyForJob(false)}
        />
      )}
    </>
  );
};

export default Applyforjob;

import React from 'react'

const Appdevelopment = () => {

    const card = [
        {
            img: "./img/portfolio_aaps (1).jpg",
            text: "SMS Translate Manager"
        },
        {
            img: "./img/portfolio_aaps (2).jpg",
            text: "Weather App"
        },
        {
            img: "./img/portfolio_aaps (3).jpg",
            text: "Messanger"
        },
        {
            img: "./img/portfolio_aaps (4).jpg",
            text: "Pro File Manager"
        },
        {
            img: "./img/portfolio_aaps (5).jpg",
            text: "Online Course App"
        },
        {
            img: "./img/portfolio_aaps (6).jpg",
            text: "My Wallet"
        }
    ]

    return (
        <>
            <div className="tab-content">
                {card.map((item) =>
                    <div class="tab-card">
                        <div className="tab-img"><img src={item.img} alt="" /></div>
                        <h5>{item.text}</h5>
                    </div>
                )}
            </div>
        </>
    )
}

export default Appdevelopment

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const WebDevelopment = () => {
    return (
        <>
             <div className="services-bg">
      <Container>
        <Row>
        <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center header-content flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                {/* <div className=" text-left">
                  <span className="welcome">WEB-DEVLOPMENT</span>
                </div> */}
                <div>
                  <h1 className="separator_off">
                  WEB-DEVLOPMENT
                  </h1>
                  {/* <br />
                  <h6 className="subtitle">
                  Website and App development solution for transforming and innovating businesses.
                  and innovating businesses.
                  </h6> */}
                </div>
              </div>
            </Col>  
          {/* <Col>
          <div className="android-img services-inner-img">
                <img src="./img/WEB-DEVLOPMENT.png" width={"90%"} />
              </div>
          </Col> */}
        </Row>
        </Container>
    </div>
            <Container>
                <Row>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-img services-inner-img">
                            <img src="./img/WEB-DEVLOPMENT.png" alt="" />
                        </div>
                    </Col>
                    <Col mad={6} lg={12} xl={6}>
                        <div className="android-text">
                            <h2>WEB DEVELOPMENT</h2>
                            <p>7SEASOL is having its expertise in web application development and web based software product development leveraging open-source platforms. 7SEASOL as a technology partner in conceptualization, design, development and maintenance of web based products provides best services to their clients.<br/><br/>Below is the indicative list of services under web development:</p>
                            <ul>How We do it?</ul>
                            <li>- Custom iPhone App Development</li>
                            <li>- Native iPhone App Development</li>
                            <li>- iPhone App Upgrade</li>
                            <li>- Hybrid iPhone App Development</li>
                            <li>- iPhone App UI/UX Designing</li>
                            <li>- iPhone App Testing/Portability</li><br/>
                            <p>Apart from excellence in Web development, our company ensures proper database integration with the website, that provides your customers more simple and user-friendly interfaces that help you do business online more effectively.</p>
                        </div>
                        <div className="android-text">
                            <ul>Our Source</ul>
                            <li>React Development</li>
                            <li>Angular Development</li>
                            <li>Node.JS Development</li>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default WebDevelopment

import axios from 'axios'
import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { base_url } from '../../config/baseURL'
import { endPoints } from '../../config/endPoints'
import InputField from '../../reusebleComponents/Inputfield'

const Hiringdeveloper = () => {

    const hiringDeveloperFormData = {
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        mobileNo: null,
        coustomRequirement: ""
    }

    const [formData, setFormData] = useState(hiringDeveloperFormData)
    const [service, setService] = useState()
    const [requirement, setRequirement] = useState()

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmitClick = () => {
        // axios.post("http://192.168.1.13:8080/hireOurtalent",formData).then((res) => console.log(res)).catch((error) => console.log(error))
        axios.post(`${base_url}${endPoints.hireOurtalent}`,formData).then((res) => console.log(res)).catch((error) => console.log(error))
    }
    return (
        <>
            <div className="hiring-developer">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="set-input">
                                <InputField
                                    name={"firstName"}
                                    maxLength={""}
                                    value={formData.firstName}
                                    lablestyleClass={""}
                                    InputstyleClass={"contact-input"}
                                    onChange={(e) => handleInputChange(e)}
                                    label={"first Name"}
                                    placeholder={"first Name"}
                                    fromrowStyleclass={""}
                                />
                                <InputField
                                    name={"lastName"}
                                    maxLength={""}
                                    value={formData.lastName}
                                    lablestyleClass={""}
                                    InputstyleClass={"contact-input"}
                                    onChange={(e) => handleInputChange(e)}
                                    label={"Last Name"}
                                    placeholder={"Enter Last Name"}
                                    fromrowStyleclass={""}
                                />
                            </div>
                            <InputField
                                name={"email"}
                                maxLength={""}
                                value={formData.email}
                                lablestyleClass={""}
                                InputstyleClass={"contact-input"}
                                onChange={(e) => handleInputChange(e)}
                                label={"Email"}
                                placeholder={"Your Email Address"}
                                fromrowStyleclass={""}
                            />
                            <InputField
                                name={"subject"}
                                maxLength={""}
                                value={formData.subject}
                                lablestyleClass={""}
                                InputstyleClass={"contact-input"}
                                onChange={(e) => handleInputChange(e)}
                                label={"Subject"}
                                placeholder={"Subject"}
                                fromrowStyleclass={""}
                            />
                            <InputField
                                name={"mobileNo"}
                                maxLength={""}
                                value={formData.mobileNo}
                                lablestyleClass={""}
                                InputstyleClass={"contact-input"}
                                onChange={(e) => handleInputChange(e)}
                                label={"Mobile phone"}
                                placeholder={"Number"}
                                type={"Number"}
                                fromrowStyleclass={""}
                            />
                            <label className="form-label">Select a Service</label>
                            <Form.Select aria-label="Floating label select example" className="contact-input" onChange = {(e) => setService(e.target.value)}>
                                <option>select</option>
                                <option value="Mobile Application developement">Mobile Application developement</option>
                                <option value="Web developement">Web developement</option>
                                <option value="UI/UX Desig">UI/UX Desig</option>
                                <option value="Digital Marketing">Digital Marketing</option>
                                <option value="SEO/ASO">SEO/ASO</option>
                                <option value="Game developement">Game developement</option>
                                <option value="Customized Open Source Project">Customized Open Source Project</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                            <label className="form-label">Requirement</label>
                            <Form.Select aria-label="Floating label select example" className="contact-input" onChange = {(e) => setRequirement(e.target.value)}>
                                <option>select</option>
                                <option value="Make A New Project">Make A New Project</option>
                                <option value="Existing Project">Existing Project</option>
                                <option value="About Service & Management">About Service & Management</option>
                                <option value="consultant">consultant</option>
                            </Form.Select>
                            <InputField
                                name={"coustomRequirement"}
                                maxLength={""}
                                value={formData.coustomRequirement}
                                lablestyleClass={""}
                                InputstyleClass={"contact-input"}
                                rows={4}
                                onChange={(e) => handleInputChange(e)}
                                label={"Requirement"}
                                placeholder={"Write Your Custom Requirement"}
                                type={"textarea"}
                                fromrowStyleclass={""}
                            />
                            {/* <label htmlFor="" className="form-label">Requirement</label>
                            <Form.Control as="textarea" rows={4} placeholder="Write Your Custom Requirement" /> */}
                            <button className="contact-send-btn" onClick = {() => handleSubmitClick()}>Submit</button>
                        </Col>
                        <Col md={6} className="contact-address">
                            <div className="">
                                <h4>Address</h4>
                                <p>2080, Silver Business Point, VIP Circle, Uttran, SURAT-394105 <br /> Gujarat, INDIA</p><br />
                                <div className="contact-number">
                                    <div>
                                        <h4>Phones</h4>
                                        <p><span className="address-email">Phone:</span> +91 8200245456</p><br />
                                    </div>
                                    <div>
                                        <h4>Email Addresses</h4>
                                        <p> <span className="address-email">Email:</span> info@7seasol.com</p><br />
                                    </div>
                                </div>
                                <div>
                                    <h4>Connect</h4>
                                    <div className="connect-logos">
                                        <img src="./img/facebook.png" alt="" className="facebook-logo" />
                                        <img src="./img/twitter.png" alt="" className="facebook-logo" />
                                        <img src="./img/linkedin.png" alt="" className="facebook-logo" />
                                        <img src="./img/google.png" alt="" className="facebook-logo" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Hiringdeveloper

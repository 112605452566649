import React from "react";
import { Container, Row } from "react-bootstrap";

const Fetures = () => {
  const features = [
    {
      bg_img: "./img/round-1.png",
      inner_image: "./img/idea.png",
      head: "Learning & Planing",
      body: "Before starting any project, We understand the domain & goals. We use our experties & obtained knowledge to plan and estimate the project.",
    },
    {
      bg_img: "./img/round-2.png",
      inner_image: "./img/design-devlopment.png",
      head: "Ux Design & Devlopment",
      body: "Our designers will create a visual layer of your software project, make a outcome would be exact screens of app and web",
      ml_100: "margin-left-100"
    },
    {
      bg_img: "./img/round-3.png",
      inner_image: "./img/launching.png",
      head: "Testing & Qulity Assntance",
      body: "To ensure the qulity of software we do enough testing while devlopment additionally. We always from a full testing & on before delivering the products.",
    },
    {
      bg_img: "./img/round-4.png",
      inner_image: "./img/maintenance.png",
      head: "Maintenance",
      body: "When prodnets accessible for you and users,we are also producing a long term support such as updates, files and change as per your mind.",
      ml_100: "margin-left-100"
    },
  ];

  return (
    <>
      {/* <div className="row features"> */}
      <Container>
        <Row>
          <div className="features col-xl-6 mb-0">
            <div className="features-left ">
              <div className="title-top margin-left-right mb-4">OUR PROCESS</div>
              <h2 className="title text-md-start">
                Top-rated Web And Mobile App <br />
                Development Company
              </h2>
            </div>
            <div className="features-right">
              <div className="features-right-data">
                {features.map((item) => (
                  <div className={window.innerWidth <= 768 ? `text-center mb-3 align-items-center features-right-info` : `d-flex mb-4 align-items-center features-right-info ${item.ml_100}`}>
                    <div className="set-features-images">
                      <img src={item.bg_img} className="features-bg-img" />
                      <img
                        className="inner-image"
                        src={item.inner_image}
                      ></img>
                    </div>
                    <div className="ps-3">
                      <h3>{item.head}</h3>
                      <p>
                        {item.body}
                      </p>
                    </div>
                  </div>
                ))}
                {/* <div className="d-flex ms-5 mb-3 align-items-center features-right-info">
                  <img src="./img/round-2.png"></img>
                  <div className="ps-3">
                    <h3>Designing & Developing</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      <br></br>
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      <br></br>{" "}
                    </p>
                  </div>
                </div> */}
                {/* <div className="d-flex mb-3 align-items-center features-right-info">
                  <div className="position-relative">
                    <img src="./img/round-3.png"></img>
                    <img
                      className="inner-image"
                      src="./img/idea.png"
                      width={"70px"}
                    ></img>
                  </div>
                  <div className="ps-3">
                    <h3>Testing & Lunching</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      <br></br>
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      <br></br>
                      aliqua.{" "}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <img src="./img/coding.jpg" className={window.innerWidth <= 768 ? "w-100" : ""} />
          </div>
        </Row>
      </Container>
      {/* </div> */}
    </>
  );
};

export default Fetures;

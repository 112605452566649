import React, { useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import Appdevelopment from './component/Appdevelopment'
import Uiux from './component/Uiux'
import Webdevelopment from './component/Webdevelopment'

const Portfolio = () => {

  const [webDevelopment, setWebDevelopment] = useState(true)
  const [appdevelopment, setAppDevelopment] = useState(false)
  const [uiux, setUIUX] = useState(false)

  const setweb = () => {
    setAppDevelopment(false)
    setUIUX(false)
  }

  const setapp = () => {
    setWebDevelopment(false)
    setUIUX(false)
  }

  const setui = () => {
    setWebDevelopment(false)
    setAppDevelopment(false)
  }

  return (
    <>
      <div className="service-bg">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center header-content flex-column text-left text-white gy-5"
                style={{ rowGap: "20px" }}
              >
                <div className=" text-left">
                  <span className="welcome">OUR PORTFOLIO</span>
                </div>
                <div>
                  <h1 className="separator_off">
                    Our Letest Work in Different technologies
                  </h1>
                  
                  <h6 className="subtitle">
                  We don’t have much to say, our portfolio speaks it all. It is crafted by our skilled employees with care.
                  </h6>
                </div>
              </div>
            </Col>
            <Col className="services-img" >
              <img src="./img/portfolio-banner.png" width={"100%"} />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <div className="ourprotfiloyo-button">
          <button onClick={() => { setweb(setWebDevelopment(true)) }} className={webDevelopment ? "buttonaction" : "buttondesbal"}>Web Development</button>
          <button onClick={() => { setapp(setAppDevelopment(true)) }} className={appdevelopment ? "buttonaction" : "buttondesbal"}>App Development</button>
          <button onClick={() => { setui(setUIUX(true)) }} className={uiux ? "buttonaction" : "buttondesbal"}>UI/UX</button>
        </div>
        <div className="ourprotfiloyo-border-content"></div>
        <div className="ourprotfiloyo-content">
          {webDevelopment && <Webdevelopment />}
          {appdevelopment && <Appdevelopment />}
          {uiux && <Uiux />}
        </div>

        {/* <Tabs
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Home">
            <Webdevelopment />
          </Tab>
          <Tab eventKey="profile" title="Profile">
            <Appdevelopment />
          </Tab>
          <Tab eventKey="contact" title="Contact">
            <Uiux />
          </Tab>
        </Tabs> */}
      </Container>
    </>
  )
}

export default Portfolio

import React, { useEffect, useState } from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import $ from "jquery";
import Sidebar from "./sidebar/Sidebar";
import Button from '@restart/ui/esm/Button'

const Layout = ({ children, ...props }) => {
  const [top, setTop] = useState(false);
  useEffect(() => {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }, [children, top]);

  return (
    <>
      <div className="position-relative">
        <Sidebar />
        <Header />
      </div>
      <div className="perent padding-bottom-80px"    {...props}>
        {children}
      </div>
      <Button className="return-to-top" onClick={() => setTop(!top)}> <img src="./img/arrow.png" alt=""/></Button>
      <Footer />
    </>
  );
};

export default Layout;

import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

const Service = () => {
  const history = useHistory()
  const aboutcard = [
    {
      img: "./img/ANDROID-DEVELOPMENT.png",
      title: "ANDROID DEVELOPMENT",
      Sourcetitle: "Our Source",
      Source1: "java",
      Source2: "React Native",
      Source3: "kotlin",
      Source4: "SDK",
      Source5: "Android Studio",
      buttonpath: "/android-development",
      button: "View more",
    },
    {
      img: "./img/IOS.png",
      title: "IOS DEVELOPMENT",
      Sourcetitle: "Our Source",
      Source1: "xocde",
      Source2: "Objective - C",
      Source3: "Swift",
      button: "View more",
      buttonpath: "/ios-development",
    },
    {
      img: "./img/WEB-DEVLOPMENT.png",
      title: "WEB-DEVLOPMENT",
      Sourcetitle: "Our Source",
      Source1: "React Development",
      Source2: "Angular Development",
      Source3: "Node.JS Development",
      button: "View more",
      buttonpath: "/web-development",
    },
    {
      img: "./img/GAME DEVELOPMENT.png",
      title: "GAME DEVELOPMENT",
      Sourcetitle: "Our Source",
      Source1: "C#",
      Source2: "Unity 3d enegin",
      Source3: "SDK",
      Source4: "Android",
      Source5: "IOS",
      Source6: "Desktop Games",
      button: "View more",
      buttonpath: "/game-development",
    },
    {
      img: "./img/SEO1.png",
      title: "SEO DEVELOPMENT",
      Sourcetitle: "Our Source",
      Source1: "Social Bookmarking",
      Source2: "Guest Post",
      Source3: "Outreach Post",
      Source4: "Video Submission",
      button: "View more",
      buttonpath: "/seo-development",
    },
    {
      img: "./img/WEB DESIGN.png",
      title: "WEB AND APP DESIGN",
      Sourcetitle: "Our Source",
      Source1: "Adobe Photoshop",
      Source2: "Figma",
      Source3: "Adobe Illustrator",
      Source4: "HTML",
      Source5: "Scss",
      Source6: "Tailwindcss",
      button: "View more",
      buttonpath: "/web-and-app-design",
    },
  ];

  const Viewmore = (item) => {
    history.push(item)
  }

  return (
    <>
      <div className="service-bg">
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                className="h-100 w-100 d-flex justify-content-center  flex-column text-left text-white gy-5 header-content"
                style={{ rowGap: "20px" }}
              >
                <div className=" text-left">
                  <span className="welcome">OUR SERVICES</span>
                </div>
                <div>
                  <h1 className="separator_off">
                    Need A Premium & Creative Website/application Designing?
                  </h1>
                  
                  <h6 className="subtitle">
                  Instead of focusing on the competition, focus on the customer
                  </h6>
                </div>
              </div>
            </Col>
            <Col className="services-img">
              <img src="./img/services-banner.png" width="100%" />
            </Col>
          </Row>
        </Container>
      </div>


      <Container>
        <div className="about-service mt-5">
          <div className="title-top">OUR SERVICES</div>
          <h2 className="title">
            Top-rated Web And Mobile App <br />
            Development Company
          </h2>
          <p className="text">
            7SEASOL is IT Service company which established in 2018.Our company
            provide services of website designing, logo development, digital
            marketing, game development and many more. If you are searching for
            superior, dedicated and innovative technology served in applications
            and websites then you are at your destination. We serves the best of
            us to our clients. Where the technology feels delighted, for sure
            there employees are inventive, ingenious and imaginative. Our
            employees are “The Beats” of the company. The values of our company
            are collaboration, integrity and accountability which committed in
            our heart and mind.
          </p>
          <div className="about-card">
            {aboutcard.map((diteal, i) => (
              <div class="card-container">
                <div class="front">
                  <img src={diteal.img} alt="" />
                  <h3>{diteal.title}</h3>
                </div>
                <div class="back">
                  <div className="our-source">
                    <ul>{diteal.Sourcetitle}</ul>
                    {diteal.Source1 && <li>{diteal.Source1}</li>}
                    {diteal.Source2 && <li>{diteal.Source2}</li>}
                    {diteal.Source3 && <li>{diteal.Source3}</li>}
                    {diteal.Source4 && <li>{diteal.Source4}</li>}
                    {diteal.Source5 && <li>{diteal.Source5}</li>}
                    {diteal.Source6 && <li>{diteal.Source6}</li>}
                  </div>
                  {/* <Link to={diteal.buttonpath} className="Viewmore-btn">{diteal.button}</Link> */}
                  <button className="btn-join-now" onClick={() => { history.push(diteal.buttonpath) }}> {diteal.button} </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Service;

import Button from '@restart/ui/esm/Button'
import axios from 'axios'
import Upload from 'rc-upload'
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { base_url } from '../../config/baseURL'
import { endPoints } from '../../config/endPoints'
import InputField from '../../reusebleComponents/Inputfield'

const Applyforjobmodel = ({ show, onHide }) => {

    const uploadProps = {
        accept: "application/pdf",
        customRequest({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials,
        }) {
            if (file && file?.type === "application/pdf") {
                setFile(file)
            } else {
                setFileError("Please Select PDF Format")
                setFile(null)
            }
        }
    }

    const apllyForJobFormData = {
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        mobileNo: null,
        file: []
    }

    const [file, setFile] = useState(null)
    const [fileError, setFileError] = useState("")
    const [formData, setFormData] = useState(apllyForJobFormData)
    const [submitDis, setSubmitDis] = useState(true)

    const fileHeandel = (_file) => {
        if (_file && _file?.type === "application/pdf") {
            setFile(_file)
        } else {
            setFileError("Please Select PDF Format")
        }
    }

    useEffect(() => {
        if (formData?.firstName?.length > 0 && formData?.lastName?.length > 0 && formData?.email?.length > 0 && formData?.address?.length > 0 && formData?.mobileNo?.length > 0 && file?.name?.length > 0) {
            setSubmitDis(false)
        }
        else {
            setSubmitDis(true)
        }
    }, [formData, file])

    useEffect(() => {
        console.log("file", file?.name);
    }, [file])

    const handleInputChange = (e) => {
        // console.log("e.target.name", e.target.name);
        // if (e.target.name === "file") {
        //     console.log("e.target.name", e.target.name);
        //     if (e.target.files[0] && e.target?.files[0]?.type === "application/pdf") {
        //         console.log("e.target?.files[0]", e.target?.files[0]);
        //         setFormData({ ...formData, file: e.target?.files[0] })
        //         //         setFile(_file)
        //     } else {
        //         setFileError("Please Select PDF Format")
        //     }
        // } else {
        setFormData({ ...formData, [e.target.name]: e.target.value })

        // }


    }

    const handleSubmitClick = () => {

        // console.log(file);
        // if (file && file.type === "application/pdf") {
        //     console.log("valide file ");
        //     setFormData({ ...formData, file: file })
        // }
        // console.log("formData", formData);
        let _formData = new FormData();
        _formData.append("firstName", formData.firstName)
        _formData.append("lastName", formData.lastName)
        _formData.append("email", formData.email)
        _formData.append("address", formData.address)
        _formData.append("mobileNo", formData.mobileNo)

        if (file) {
            _formData.append("resume", file)
        }

        // axios.post("http://192.168.1.13:8080/applyForJob", _formData, { headers: {'Content-Type': 'multipart/form-data' }}).then((res) => console.log(res)).catch((error) => console.log(error))
        axios.post(`${base_url}${endPoints.applyForJob}`, _formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => console.log(res)).catch((error) => console.log(error))
        onHide()
    }
    return (
        <>
            {/* <h1>Applyforjobmodel</h1> */}
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            // className="contract-edit-model"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Apply For Job
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputField
                        name={"firstName"}
                        maxLength={""}
                        value={formData.firstName}
                        lablestyleClass={""}
                        InputstyleClass={"contact-input"}
                        onChange={(e) => handleInputChange(e)}
                        label={"Name"}
                        placeholder={"First Name"}
                        fromrowStyleclass={""}
                    />
                    <InputField
                        name={"lastName"}
                        maxLength={""}
                        value={formData.lastName}
                        lablestyleClass={""}
                        InputstyleClass={"contact-input"}
                        onChange={(e) => handleInputChange(e)}
                        label={"Last Name"}
                        placeholder={"Enter Last Name"}
                        fromrowStyleclass={""}
                    />
                    {/* </div> */}
                    <InputField
                        name={"email"}
                        maxLength={""}
                        value={formData.email}
                        lablestyleClass={""}
                        InputstyleClass={"contact-input"}
                        onChange={(e) => handleInputChange(e)}
                        label={"Email"}
                        placeholder={"Your Email Address"}
                        fromrowStyleclass={""}
                    />
                    <InputField
                        name={"mobileNo"}
                        maxLength={""}
                        value={formData.mobileNo}
                        lablestyleClass={""}
                        InputstyleClass={"contact-input"}
                        onChange={(e) => handleInputChange(e)}
                        label={"Mobile phone"}
                        placeholder={"Number"}
                        type={"number"}
                        fromrowStyleclass={""}
                    />
                    <InputField
                        name={"address"}
                        maxLength={""}
                        value={formData.address}
                        lablestyleClass={""}
                        InputstyleClass={"contact-input"}
                        rows={3}
                        onChange={(e) => handleInputChange(e)}
                        label={"Your Address"}
                        placeholder={"Your Address"}
                        type={"textarea"}
                        fromrowStyleclass={""}
                    />
                    <label className="form-label d-block">Drop Your Resume</label>
                    <Upload {...uploadProps} id="test" component="div" style={{ display: 'inline-block', width:'100%' }} >
                        <div className="resume-file">
                            <p>Drop file here or click to upload</p>
                            {file && <p>{file?.name}</p>}
                        </div>
                    </Upload>
                    {fileError && <span className="file-error">{fileError}</span>}
                </Modal.Body>
                <Modal.Footer>
                    <button className={submitDis ? "btn4d73e2-disable" : "btn4d73e2-active"} disabled={submitDis} onClick={() => handleSubmitClick()}>Submit</button>
                    <Button className="btn-join-now" onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Applyforjobmodel

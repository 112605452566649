// import { IS_LOADING } from '../types';

import { IS_OPEN } from "../types";

const initialState = {
  is_loading: false,
};

export const LoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return {
        ...state,
        is_loading: action.payload,
      };

    default:
      return state;
  }
};


import axios from 'axios'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { base_url } from '../../config/baseURL'
import { endPoints } from '../../config/endPoints'
import InputField from '../../reusebleComponents/Inputfield'

const Contact = () => {

    const contactFormData = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }

    const [formData, setFormData] = useState(contactFormData)

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const handleSubmitClick = () => {
        // axios.post("http://192.168.1.13:8080/contact",formData).then((res) => console.log(res)).catch((error) => console.log(error))
        axios.post(`${base_url}${endPoints.contact}`, formData).then((res) => console.log(res)).catch((error) => console.log(error))
        setFormData(contactFormData)
    }
    return (
        <>
            {/* {
             isLoading && <Loader/>
        }
        */}
            {/* <div className="contact-bg">
                <h1>Contact</h1>
            </div> */}
            <div className="services-bg">
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div
                                className="h-100 w-100 d-flex justify-content-center header-content flex-column text-left text-white gy-5"
                                style={{ rowGap: "20px" }}
                            >
                                {/* <div className=" text-left">
                                    <span className="welcome">Contact US</span>
                                </div> */}
                                <div>
                                    <h1 className="separator_off">
                                    Get in touch
                                    </h1>
                                    <br />
                                    
                                </div>
                            </div>
                        </Col>
                        {/* <Col className="services-img">
                            <img src="./img/contact-banner.png" width={"100%"} />
                        </Col> */}
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col md={6}>
                        <InputField
                            name={"name"}
                            maxLength={""}
                            value={formData.name}
                            lablestyleClass={""}
                            InputstyleClass={"contact-input"}
                            onChange={(e) => handleInputChange(e)}
                            label={""}
                            placeholder={"Name"}
                            fromrowStyleclass={""}
                        />
                        <InputField
                            name={"email"}
                            maxLength={""}
                            value={formData.email}
                            lablestyleClass={""}
                            InputstyleClass={"contact-input"}
                            onChange={(e) => handleInputChange(e)}
                            label={""}
                            placeholder={"Email"}
                            fromrowStyleclass={""}
                        />
                        <InputField
                            name={"subject"}
                            maxLength={""}
                            value={formData.subject}
                            lablestyleClass={""}
                            InputstyleClass={"contact-input"}
                            onChange={(e) => handleInputChange(e)}
                            label={""}
                            placeholder={"Subject"}
                            fromrowStyleclass={""}
                        />
                        <InputField
                            name={"message"}
                            maxLength={""}
                            value={formData.message}
                            lablestyleClass={""}
                            InputstyleClass={"contact-input"}
                            rows={4}
                            onChange={(e) => handleInputChange(e)}
                            label={""}
                            placeholder={"Message"}
                            type={"textarea"}
                            fromrowStyleclass={""}
                        />
                        <button className="btn-join-now" onClick={() => handleSubmitClick()}>Send Message</button>
                    </Col>
                    <Col md={6} className="contact-address">
                        <div className="">
                            <h4 className='title-theme-head'>Address</h4>
                            <p className='title-theme-p'>2080, Silver Business Point, VIP Circle, Uttran, SURAT-394105 <br /> Gujarat, INDIA</p><br />
                            <div className="contact-number">
                                <div>
                                    <h4 className='title-theme-head'>Phones</h4>
                                    <p className='title-theme-p'><span className="address-email">Phone:</span> +91 8200245456</p><br />
                                </div>
                                <div>
                                    <h4 className='title-theme-head'>Email Addresses</h4>
                                    <p className='title-theme-p'> <span className="address-email">Email:</span> info@7seasol.com</p><br />
                                </div>
                            </div>
                            <div>
                                <h4 className='title-theme-head'>Connect</h4>
                                <div className="connect-logos">
                                    {/* <img src="./img/facebook.png" alt="" className="facebook-logo" />
                                    <img src="./img/twitter.png" alt="" className="facebook-logo" />
                                    <img src="./img/linkedin.png" alt="" className="facebook-logo" />
                                    <img src="./img/google.png" alt="" className="facebook-logo" /> */}
                                    <a
                                        href="https://www.linkedin.com/company/7seasol"
                                        target="_blank"
                                    >
                                        <img
                                            src="./img/linkedin.png"
                                            alt=""
                                            className="facebook-logo"
                                        />
                                    </a>
                                    <a
                                        href="https://www.google.com/search?q=7seasol&rlz=1C1CHBF_enIN926IN926&oq=7seasol&aqs=chrome.0.69i59j35i39j69i60j69i61j69i60l4.1652j0j7&sourceid=chrome&ie=UTF-8"
                                        target="_blank"
                                    >
                                        <img
                                            src="./img/google.png"
                                            alt=""
                                            className="facebook-logo"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="connect-map">
                    <iframe src="https://maps.google.com/maps?q=silver%20business%20point&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" ></iframe>
                </div>
            </Container>

        </>
    )
}
export default Contact

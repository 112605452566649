import React from 'react'

const Webdevelopment = () => {

    const card = [
        {
            img: "./img/portfolio_web (1).jpg",
            text: "Focus FS"
        },
        {
            img: "./img/portfolio_web (2).jpg",
            text: " Trublu" 
        },
        {
            img: "./img/portfolio_web (3).jpg",
            text: "Scogo"
        },
        {
            img: "./img/portfolio_web (4).jpg",
            text: "The Royal Baby" 
        },
        {
            img: "./img/portfolio_web (5).jpg",
            text: "Boomio"
        },
        {
            img: "./img/portfolio_web (6).jpg",
            text: "CRM" 
        },
        {
            img: "./img/portfolio_web (7).jpg",
            text: "CRM" 
        }
    ]

    return (
        <>
            <div className="tab-content">
                {card.map((item)=>
                    <div class="tab-card">
                        <div className="tab-img"><img src={item.img} alt="" /></div>
                        <h5>{item.text}</h5>
                    </div>
                )}
            </div>
        </>
    )
}

export default Webdevelopment

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import * as  querystring from 'query-string'
import { jq } from './jq.js'
import './life7seasol.css';



const Life7seasolcomponent = () => {
  useEffect(() => {
    jq()
  }, [])

  const data = [
    {
      id: 1,
      img: "../img/about-1.png",
      text: "ANDROID DEVELOPMENT 1"
    },
    {
      id: 2,
      img: "../img/about-2.png",
      text: "ANDROID DEVELOPMENT 2"
    },
    {
      id: 3,
      img: "../img/about-1.png",
      text: "ANDROID DEVELOPMENT 3"
    },
    {
      id: 4,
      img: "../img/about-2.png",
      text: "ANDROID DEVELOPMENT 4"
    },
    {
      id: 5,
      img: "../img/about-1.png",
      text: "ANDROID DEVELOPMENT 5"
    },
    {
      id: 6,
      img: "../img/about-2.png",
      text: "ANDROID DEVELOPMENT 6"
    }
  ]

  const history = useHistory()
  const searchParams = querystring.parse(history.location.search)

  const [eventName, setEventName] = useState("")

  useEffect(() => {
    if (searchParams?.event) {
      setEventName(searchParams?.event)
    } else {
      // handletab('employees')  
      history.push({
        pathname: history.location,
        search: `?event=noevent`
      })
    }
  }, [])

  return (
    <>
      <section id="gallery">
        <div class="container">
          <div id="image-gallery">
            <div class="row">
              {data.map((item) =>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                  <div class="img-wrapper">
                    <a href={item.img}><img src={item.img} class="img-responsive" alt="" /></a>
                    <div class="img-overlay">
                      {/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Life7seasolcomponent

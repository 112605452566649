import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';

import Routes from './routes/Routes';
import store from './redux/store';
import { Suspense } from 'react';

function App() {
  return (
    <Suspense fallback={false}>

      <Provider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
}

export default App;
